.navigation {
  display: flex;
  flex: 1;
  line-height: 20px;
  margin-left: 48px;
}

.navigation a {
  color: white !important;
  padding: 0 16px;
  border-radius: initial;
}

.navigation a:visited {
  color: white;
}

.navigation a.active {
  box-shadow: 0 -2px 0 var(--sm-primary-color) inset;
}

.cta {
  color: var(--sm-primary-color) !important;
}

.cta p {
  margin: 0px;
}

.nav-link {
  border-bottom: 1px solid #dadce0;
  color: #5f6368;
  font-size: 14px;
  font-weight: 500;
  padding: 16px 24px;
}

.auth-block,
.btns {
  display: flex;
  align-items: center;
}

.auth-block {
  width: 111.5px;
}

.btns {
  margin-left: auto;
}

.auth-block button {
  color: white;
  height: 40px;
}

.auth-block .profile {
  color: white;
  margin-right: 8px;
}

.menu-icon {
  display: none !important;
}

.drawerPaper {
  max-width: 600px;
  width: 80%;
}

.auth-block-mobile {
  margin-top: auto;
  align-self: flex-end;
  padding: 20px;
}

@media (max-width: 1365px) {
  .menu-icon {
    display: flex !important;
  }
  .navigation {
    display: none;
  }
}

@media (max-width: 560px) {
  .auth-block {
    display: none;
  }
}
