.social-links {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 250px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.social-link {
  margin: 10px;
  width: 30px;
  height: 30px;
}

@media (max-width: 900px) {
  .social-links {
    max-width: 100%;
  }
}
