.wrapper {
  width: 100%;
  max-width: 1770px;
  margin: 0px auto;
  padding: 0px 20px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0px -20px;
  flex-wrap: nowrap;
}

.footer {
  width: 100%;
  background-color: #262626;
  color: #ccc;
  padding: 20px;
}

.links {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
}

.linkLabel {
  font-size: 16px;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 1px;
  text-decoration: none;
  color: #cccccc !important;
  cursor: pointer;
  padding: 10px 15px;
}

.linkLabel:hover {
  color: var(--sm-primary-color) !important;
  text-decoration: underline;
}

.rules {
  margin: 0px -20px;
  color: #b8b8b8;
  display: flex;
  justify-content: space-between;
  letter-spacing: 0.5px;
}

.rules p {
  margin: 0;
  padding: 10px 20px;
}

@media (max-width: 1100px) {
  .container {
    flex-wrap: wrap-reverse;
  }

  .links {
    justify-content: space-around;
  }

  .link {
    font-size: 16px;
    max-width: 100% !important;
  }

  .rules {
    flex-wrap: wrap-reverse;
  }

  .rules p {
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .links {
    flex-wrap: wrap;
  }
  .link {
    width: calc(50% - 30px) !important;
  }
  .linkLabel,
  .rules {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .link {
    width: 100% !important;
    font-weight: 500;
  }
  .linkLabel,
  .rules {
    font-size: 13px;
  }
}
