.menu {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px -3px 8px rgb(0 0 0 / 8%);
  z-index: 900;
}

@media (min-width: 1366px) {
  .menu {
    display: none;
  }
}
